import logo from './images/deatyhbymushroom.png';
import ss1 from './images/dbmshot.png';
import ss1Mobile from './images/dbmshotMobile.png';
import dbmdude from './images/dbmdude.png';
import appstoreapple from './images/appstoreapple.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <div className="container">
                <div className="flex-container">
                    <div className="wrapper">
                        <div className="row">
                            <div className="flex-item text-center">
                                <img src={logo} className="mainlogo" alt="Death By Mushroom Logo"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="flex-item pt-10">
                                <div className='finalDetails-mobile ' style={{
                                    background: 'rgba(214, 238, 253, 0.2)',

                                    borderRadius: '15px',
                                    position: 'relative',


                                }}>
                                    <div style={{
                                            backgroundImage: `url(${ss1Mobile})`,
                                            backgroundRepeat: 'no-repeat', padding: '15px'}}>
                                        <h1>
                                            DEATH BY MUSHROOM
                                        </h1>
                                        <p style={{paddingBottom: '30px', paddingTop: '30px'}}>

                                            Test your knowledge and skills in the wild world of fungi! In this app, you'll face a series of trivia questions about different types of mushrooms, including both edible and poisonous varieties.

                                            As you progress through the game, you'll encounter various challenges, including identifying mushrooms based on pictures, recognizing the symptoms of mushroom poisoning, and learning how to distinguish between similar-looking species. With each correct answer, you'll earn points and advance to the next level.


                                        </p>
                                        <img src={appstoreapple} className="buylogo" alt="Death By Mushroom App Store Link" onClick={() => {
                                            window.open('https://apps.apple.com/us/app/death-by-mushroom/id1663931372');
                                        }}/>
                                    </div>
                                </div>
                                <div className="flex-container1 finalDetails" style={{
                                    background: 'rgba(214, 238, 253, 0.2)',
                                    padding: '30px',
                                    borderRadius: '15px',
                                    position: 'relative',
                                }}>
                                    <div className="flex-item1 logo-item">
                                        <img src={ss1} className="logo" alt="Last Die Logo"/>
                                    </div>
                                    <div className="flex-item2" >
                                        <h1>
                                            DEATH BY <br/> MUSHROOM
                                        </h1>
                                        <p style={{paddingBottom: '30px', paddingTop: '30px'}}>
                                            Test your knowledge and skills in the wild world of fungi! In this app, you'll face a series of trivia questions about different types of mushrooms, including both edible and poisonous varieties.

                                            As you progress through the game, you'll encounter various challenges, including identifying mushrooms based on pictures, recognizing the symptoms of mushroom poisoning, and learning how to distinguish between similar-looking species. With each correct answer, you'll earn points and advance to the next level.



                                        </p>
                                        <img src={appstoreapple} className="buylogo" alt="Death By Mushroom App Store Link" onClick={() => {
                                            window.open('https://apps.apple.com/us/app/death-by-mushroom/id1663931372');
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="flex-item pt-10">
                                <div className="flex-container1 no-flex-mobile" style={{
                                    background: 'rgba(214, 238, 253, 0.2)',
                                    padding: '30px',
                                    borderRadius: '15px'
                                }}>
                                    <div className="flex-item1-video">
                                        <h2>
                                            Mushroom Survival Trivia!
                                        </h2>
                                        <p style={{paddingTop: '30px'}}>
                                            But be careful - one wrong answer could be deadly! You'll need to use your wits and your knowledge to survive in the world of mushrooms. And if you're feeling stuck, don't worry - you can use a antidotes to help you out.
                                        </p>
                                    </div>
                                    <div className="flex-item2-video pt-10-m">
                                        <div className='video-width'>
                                            <iframe width="100%" height="315"
                                                    src="https://www.youtube.com/embed/jqFeZ3w7a8o"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="flex-item pt-10">
                                <div className="flex-container1 no-flex-mobile" style={{
                                    background: 'rgba(214, 238, 253, 0.2)',
                                    padding: '30px',
                                    borderRadius: '15px',
                                    position: "relative"
                                }}>

                                        <div className='floating'>
                                            <img src={dbmdude} className="diceline" alt="Last Die Logo"/>
                                        </div>

                                        <div className='' style={{paddingRight: '30px', paddingBottom: '30px'}}>
                                            <h3>
                                                ADD-ONS, LEADERBOARDS, AND ACHIEVEMENTS!
                                            </h3>
                                            <p style={{paddingTop: '30px'}}>
                                                Over 1000 mushrooms to challenge your skills! Over 100 herbs and 100 flower add-on packs! Track your progress with everyone else on your leaderboards. Over 50 achievements to unlock as you push your knowledge of herbs, flowers, and mushrooms!<br/><br/>
                                                With its fun and engaging gameplay, the Death By Mushroom game is perfect for anyone who loves nature, trivia, or just wants to learn more about the fascinating world of fungi. So download the app today and start your journey into the wild and wonderful world of mushrooms!
                                            </p>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-container-footer text-center">
                    <div className="wrapper">
                        <div className="footer">
                            © {new Date().getFullYear()} Reality Software Entertainment, Inc. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
